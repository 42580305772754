import React, { useEffect } from 'react';
import { Router, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import loadable from '@loadable/component'
import ReactGA from 'react-ga';

import history from '../utils/history';
import ErrorBoundary from '../utils/ErrorBoundary';

import Header from './Header';
import Footer from './Footer';

const About = loadable(() => import('../pages/About'));
const Cart = loadable(() => import('../pages/Cart'));
const Checkout = loadable(() => import('../pages/CartCheckout'));
const CartConfirm = loadable(() => import('../pages/CartConfirm'));
const CartThanks = loadable(() => import('../pages/CartThanks'));
const Checklist = loadable(() => import('../pages/Checklist'));
const ChecklistShare = loadable(() => import('../pages/ChecklistShare'));
const Home = loadable(() => import('../pages/Home'));
const Products = loadable(() => import('../pages/Products'));
const Subscriptions = loadable(() => import('../pages/Subscriptions'));
const News = loadable(() => import('../pages/News'));
const Foc = loadable(() => import('../pages/Foc'));
const FocThanks = loadable(() => import('../pages/FocThanks'));
const Search = loadable(() => import('../pages/Search'));
const NewReleases = loadable(() => import('../pages/NewReleases'));

const App = () => {
  const processing = useSelector((state) => state.processing.processing);
  useEffect(() => {
    firstLoad();
  }, []);

  const firstLoad = () => {
    window.scrollTo(0, 0)
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  const renderProcessing = () => {
    if (!processing) {
      return null;
    }
    return (
      <div className="loading--processing">
        <div>
          <p>Processing&hellip;</p>
          <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
        </div>
      </div>
    )
  }

  return (
    <Router history={history}>
      {renderProcessing()}
      <div className="app">
        <Header />
        <div
          role="main"
          className="main"
        >
          <div className="site-content">
            <ErrorBoundary>
              <Route exact path="/" component={Home} />
              <Route path="/about" component={About} />
              <Route path="/checklist-share/:id" component={ChecklistShare} />
              <Route path="/checklist" component={Checklist} />
              <Route path="/final-order-cutoff/thanks" component={FocThanks} />
              <Route exact path="/final-order-cutoff" component={Foc} />
              <Route path="/cart/checkout" component={Checkout} />
              <Route path="/cart/confirm" component={CartConfirm} />
              <Route path="/cart/thanks/:keygen?" component={CartThanks} />
              <Route exact path="/cart" component={Cart} />
              <Route path="/products/:token/:url_title" component={Products} />
              <Route exact path="/subscriptions" component={Subscriptions} />
              <Route path="/news/:slug" component={News} />
              <Route path="/search" component={Search} />
              <Route path="/new-releases" component={NewReleases} />
            </ErrorBoundary>
          </div>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
