import axios from 'axios';
import { reset } from "redux-form";
import * as Sentry from '@sentry/browser';

import history from '../../utils/history';
import * as keys from '../../utils/env';
import * as types from './types';
/* eslint camelcase: 0 */

const ROOT_URL = keys.ROOT_URL;
const LOCATION_URL = keys.LOCATION_URL;

let config = {
  headers: {
    'X-API-KEY': 'zeusapi',
    token: localStorage.getItem('token'),
    Accept: 'application/json',
  },
};

const updateConfig = () => {
  config = {
    headers: {
      'X-API-KEY': 'zeusapi',
      token: localStorage.getItem('token'),
    },
  };
}

const removeLocalStorage = item => localStorage.removeItem(item);

const addLocalStorage = (item, value) => localStorage.setItem(item, value)

const push = url => history.push(url);

const setSetry = (error, action) =>{
  Sentry.withScope(() => {
    Sentry.captureException(error);
    Sentry.setExtra(`action`, action);
  });
}

const getJson = (path) => {
  // console.log(`getJason`, path); // eslint-disable-line no-console
  return axios.get(`${ROOT_URL}${path}`, config)
};

const postJson = (path, data) => axios.post(`${ROOT_URL}${path}`, data, config);

export const fetchPicks = () => {
  return (dispatch) => {
    getJson(`/home`)
      .then(response => dispatch({ type: types.FETCH_PICKS_SUCCESS, payload: response.data.data }))
      .catch(error => setSetry(error, `fetchPicks`));
  }
}

export const fetchFeature = () => {
  return (dispatch) => {
    getJson(`/home/feature`)
      .then(response => dispatch({ type: types.FETCH_FEATURE_SUCCESS, payload: response.data.data }))
      .catch(error => setSetry(error, `fetchFeature`));
  }
}

export const fetchFeatureFoc = () => {
  return (dispatch) => {
    getJson(`/home/foc`)
      .then(response => dispatch({ type: types.FETCH_FEATURE_FOC_SUCCESS, payload: response.data.data }))
      .catch(error => setSetry(error, `fetchFeatureFoc`));
  }
}

export const changeSearchTerm = (term) => {
  return dispatch => dispatch({ type: types.UPDATE_SEARCH_TERM, payload: term })
}

export const fetchSearchResults = (term, page = 1) => {
  return (dispatch) => {
    getJson(`/search?term=${term}&page=${page}`)
      .then((response) => {
        dispatch({ type: types.FETCH_SEARCH_RESULTS, payload: response.data.data.response });
        dispatch({ type: types.UPDATE_SEARCH_TERM, payload: term });
      })
      .catch(error => setSetry(error, `fetchSearchResults`));
  }
}

export const fetchProduct = (id, urlTitle) => {
  return (dispatch) => {
    getJson(`/products?id=${id}&url_title=${urlTitle}`)
      .then(response => dispatch({ type: types.FETCH_PRODUCT, payload: response.data.data }))
      .catch(error => setSetry(error, `fetchProduct`));
  }
}

export const setProduct = (product) => {
  return (dispatch) => {
    dispatch({ type: types.FETCH_PRODUCT, payload: product });
  }
}

export const fetchRelatedProducts = (urlTitle) => {
  return (dispatch) => {
    getJson(`/search?page=1&term=${urlTitle}`)
      .then(response => dispatch({ type: types.FETCH_RELATED_PRODUCTS, payload: response }))
      .catch(error => setSetry(error, `fetchRelatedProducts`));
  }
}

export const clearProduct = () => {
  return (dispatch) => {
    dispatch({ type: types.CLEAR_PRODUCT, payload: '' });
    dispatch({ type: types.CLEAR_TAG, payload: '' });
  };
}

export const addProduct = (props) => {
  updateConfig();

  return (dispatch) => {
    postJson(`/cart`, props)
      .then((response) => {
        dispatch({ type: types.AUTH_USER });
        addLocalStorage('token', response.data.token);
        updateConfig();
        push('/cart');
      })
      .catch(error => setSetry(error, `addProduct`));
  }
}

export const fetchCart = (tag = false) => {
  return (dispatch) => {
    getJson(`/cart?tag=${tag}`)
      .then((response) => {
        dispatch({ type: types.AUTH_USER });
        addLocalStorage('token', response.data.token);
        updateConfig();
        dispatch({ type: types.FETCH_CART, payload: response });
      });
  }
}

export const fetchCartByKey = (keygen) => {
  return (dispatch) => {
    getJson(`/cart/key?keygen=${keygen}`)
      .then((response) => {
        dispatch({ type: types.AUTH_USER });
        dispatch({ type: types.FETCH_CART_EXPIRED, payload: response });
        dispatch({ type: types.FETCH_CART_EMPTY, payload: response });
      });
  }
}

export const fetchCategory = (category, pageNumber = 1) => {
  return (dispatch) => {
    getJson(`/category?c=${category}&p=${pageNumber}`)
      .then(response => dispatch({ type: types.FETCH_CATEGORY, payload: response.data }))
      .catch(error => setSetry(error, `fetchCategory`));
  }
}

export const fetchTag = (name, type, page = 1) => {
  return (dispatch) => {
    getJson(`/loves?n=${name}&t=${type}&p=${page}`)
      .then(response => dispatch({ type: types.FETCH_TAG, payload: response.data.data }))
      .catch(error => setSetry(error, `fetchTag`));
  }
}

export const updateProduct = (TOKEN, action) => {
  return (dispatch) => {
    postJson(`/cart/item`, { TOKEN, action })
      .then((response) => {
        if (response.data.message === 'can not increase qty') {
          alert('Cannot increase the quantity of this item');
        }

        dispatch({ type: types.FETCH_CART, payload: response });
      })
      .catch(error => setSetry(error, `updateProduct`));
  }
}

export const updateProductBag = (TOKEN) => {
  return (dispatch) => {
    postJson(`/cart/bag`, { TOKEN })
      .then(response => dispatch({ type: types.FETCH_CART, payload: response }))
      .catch(error => setSetry(error, `updateProductBag`));
  }
}

export const updateCustomer = (props) => {
  return () => {
    postJson(`/checkout`, props)
      .then(() => push('/cart/confirm'))
      .catch(error => setSetry(error, `updateCustomer`));
  }
}

export const resetCart = (props) => {
  return () => {
    postJson(`/checkout/status`, props)
      .then((response) => {
        fetchCartByKey(response.data.keygen);
        removeLocalStorage('token');
        // push(`/cart/thanks/${response.data.keygen}`);
        window.location = `${LOCATION_URL}/cart/thanks/${response.data.keygen}`;
      })
      .catch(error => setSetry(error, `resetCart`));
  }
}

export const fetchChecklist = () => {
  return (dispatch) => {
    getJson(`/checklist/feed`)
      .then(response => dispatch({ type: types.FETCH_CHECKLIST, payload: response.data }))
      .catch(error => setSetry(error, `fetchChecklist`));
  }
}

export const verifyChecklist = (selection) => {
  return (dispatch) => {
    postJson(`/checklist/verify`, { selection })
      .then(response => dispatch({type: types.VERIFY_CHECKLIST, payload: response.data }))
      .catch(error => setSetry(error, `verifyChecklist`));
  }
}

export const addChecklist = (props, formState) => {
  const { values } = formState.checklistFormHold;
  const fields = {};
  fields.checklist_type = 'hold';
  fields.fname = values.fname;
  fields.lname = values.lname;
  fields.email = values.email;
  fields.comment = values.comment ? values.comment : '';
  fields.checklist_selection = props.selection;
  fields.subtotalWithDiscount = props.subtotalWithDiscount;

  return () => {
    postJson(`/checklist/submit`, { fields })
      .then(response => {
          // push(`/checklist-share/${response.data.message}`);
          window.location = `${LOCATION_URL}/checklist-share/${response.data.message}`;
        }
      )
      .catch(error => setSetry(error, `addChecklist`));
  }
}

export const buyChecklist = (props, formState, options) => {
  // console.log(props);
  const { values } = formState.checklistFormBuy;
  const fields = {
    checklist_type: 'buy',
    billing_firstname: values.billing_firstname,
    billing_lastname: values.billing_lastname,
    billing_address1: values.billing_address1,
    billing_address2: values.billing_address2 ? values.billing_address2 : '',
    billing_city: values.billing_city,
    billing_state: values.billing_state,
    billing_zip: values.billing_zip,
    billing_email: values.billing_email,
    billing_phone: values.billing_phone,
    shipping_firstname: values.shipping_firstname ? values.shipping_firstname : '',
    shipping_lastname: values.shipping_lastname ? values.shipping_lastname : '',
    shipping_address1: values.shipping_address1 ? values.shipping_address1 : '',
    shipping_address2: values.shipping_address2 ? values.shipping_address2 : '',
    shipping_city: values.shipping_city ? values.shipping_city : '',
    shipping_state: values.shipping_state ? values.shipping_state : '',
    shipping_zip: values.shipping_zip ? values.shipping_zip : '',
    comment: values.comment ? values.comment : '',
    pickup: options.pickup,
    mylar: options.mylar,
    checklist_selection: props.selection,
    subtotal: props.subtotal,
    subtotalWithDiscount: props.subtotalWithDiscount,
    tax_amount: props.salesTax,
    shipping_amount: options.pickup === false ? props.shipping : 0,
    square_card_brand: options.squareBrand,
    square_last_four: options.squareLastFour,
  };

  return () => {
    postJson(`/checklist/submit`, { fields })
      .then(response => {
          // push(`/checklist-share/${response.data.message}`)
          window.location = `${LOCATION_URL}/checklist-share/${response.data.message}`;
        }
      )
      .catch(error => setSetry(error, `buyChecklist`));
  }
}

export const fetchChecklistShare = (id) => {
  return (dispatch) => {
    getJson(`/checklist/share?id=${id}`)
      .then(response => dispatch({ type: types.FETCH_CHECKLIST_SHARE, payload: response.data.message.product_row }));
  }
}

export const fetchNewsFeed = () => {
  return (dispatch) => {
    getJson(`/news`)
      .then(response => dispatch({ type: types.FETCH_NEWS_SUCCESS, payload: response }))
      .catch(error => setSetry(error, `fetchNewsFeed`));
  }
}

export const fetchNewsItem = (slug) => {
  return (dispatch) => {
    getJson(`/news/post?slug=${slug}`)
      .then(response => dispatch({ type: types.FETCH_NEWS_ITEM_SUCCESS, payload: response }))
      .catch(error => setSetry(error, `fetchNewsItem`));
  }
}

export const fetchNewsByTag = (slug) => {
  return (dispatch) => {
    getJson(`/news/tag?slug=${slug}`)
      .then(response => dispatch({ type: types.FETCH_NEWS_TAG_SUCCESS, payload: response }))
      .catch(error => setSetry(error, `fetchNewsByTag`));
  }
}

export const clearNews = () => {
  return dispatch => dispatch({ type: types.CLEAR_NEWS, payload: null })
}

export const clearTag = () => {
  return dispatch => dispatch({ type: types.CLEAR_TAG, payload: null });
}

export const fetchFoc = () => {
  return (dispatch) => {
    getJson(`/foc/feed`)
      .then(response => dispatch({ type: types.FETCH_FOC, payload: response.data }))
      .catch((error) => {
        setSetry(error, `fetchFoc`);
        dispatch({ type: types.ERROR_FOC, payload: {}});
      });
  }
}

export const addFocItem = (item) => {
  return dispatch => dispatch({ type: types.ADD_FOC_ITEM, payload: item });
}

export const removeFocItem = (item) => {
  return dispatch => dispatch({ type: types.REMOVE_FOC_ITEM, payload: item })
}

export const updateFocItem = (item) => {
  return dispatch => dispatch({ type: types.UPDATE_FOC_ITEM, payload: item });
}

export const buyFoc = (props, formState, options) => {
  const { values } = formState.focFormBuy;
  // checklist adds this differently
  const fields = {
    billing_firstname: values.billing_firstname,
    billing_lastname: values.billing_lastname,
    billing_address1: values.billing_address1,
    billing_address2: values.billing_address2 ? values.billing_address2 : '',
    billing_city: values.billing_city,
    billing_state: values.billing_state,
    billing_zip: values.billing_zip,
    billing_email: values.billing_email,
    billing_phone: values.billing_phone,
    shipping_firstname: values.shipping_firstname ? values.shipping_firstname : '',
    shipping_lastname: values.shipping_lastname ? values.shipping_lastname : '',
    shipping_address1: values.shipping_address1 ? values.shipping_address1 : '',
    shipping_address2: values.shipping_address2 ? values.shipping_address2 : '',
    shipping_city: values.shipping_city ? values.shipping_city : '',
    shipping_state: values.shipping_state ? values.shipping_state : '',
    shipping_zip: values.shipping_zip ? values.shipping_zip : '',
    comment: values.comment ? values.comment : '',
    subtotal: props.subtotal,
    subtotalWithDiscount: props.subtotalWithDiscount,
    mylar: props.mylarTotal,
    pickup: options.pickup,
    tax_amount: props.salesTax,
    shipping_amount: options.pickup === false ? props.shipping : 0,
    foc_selection: props.selection,
    square_card_brand: options.squareBrand,
    square_last_four: options.squareLastFour,
  };

  return (dispatch) => {
    dispatch({ type: types.PROCESSING, payload: true });
    postJson(`/foc/submit`, { fields })
      .then(() => {
        // push(`/final-order-cutoff/thanks`))
        window.location = `${LOCATION_URL}/final-order-cutoff/thanks`;
      })
      .catch(error => setSetry(error, `buyFoc`));
  };
}

export const processPayment = (nonce, amount, idempotency_key) => {
  return (dispatch) => {
    dispatch({ type: types.PROCESSING, payload: true });
    postJson(`/square`, { nonce, amount, idempotency_key })
      .then(response => dispatch({ type: types.SQUARE_SUBMIT, payload: response.data.message }))
      .catch((error) => {
        dispatch({ type: types.SQUARE_ERROR, payload: error });
        setSetry(error, `processPayment`);
      });
  }
}

export const addComment = (props, formState, formValues, options) => {
  const { values } = formState.commentForm;
  const fields = {
    name: values.name,
    email: values.email,
    location: values.location,
    rating: values.rating,
    title: values.title,
    comment: values.comment,
    product_id: options.product_id,
    url_title: options.url_title,
  };

  return (dispatch) => {
    postJson(`/comments/submit`, fields)
      .then((response) => {
        dispatch(reset("commentForm"));
        dispatch({ type: types.FETCH_PRODUCT, payload: response.data.data });
      })
      .catch(error => setSetry(error, `addComment`));
  }
}

export const voteComment = (data) => {
  return (dispatch) => {
    postJson(`/comments/vote`, data)
      .then(response => dispatch({ type: types.FETCH_PRODUCT, payload: response.data.data }))
      .catch(error => setSetry(error, `voteComment`));
  }
}
