import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui'; 
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';

const useStyles = makeStyles()(theme => ({
  root: {
    display: `flex`,
    flexWrap: `wrap`,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(3),
    width: `100%`,
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  }
}));

const Footer = () => {
  const { classes } = useStyles();

  return (
    <div className="footer">
      <section className="footer--email">
        <div className="email-form-container">
          <form
            action="//zeuscomics.us8.list-manage.com/subscribe/post?u=fd36d504a24256db62d40de2e&amp;id=fb5d0db527"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            className="email-form-form"
            target="_blank"
            noValidate
          >
            <fieldset>
              <legend>Newsletter Signup Form</legend>
              <div className={classes.root}>
                <TextField id="mce-FNAME" label="First Name" className={classes.textField} variant="outlined" />
                <TextField id="mce-LNAME" label="Last Name" className={classes.textField} variant="outlined" />
                <TextField id="mce-EMAIL" label="Email Address" className={classes.textField} variant="outlined" />
                <Button variant="contained" className={classes.button} type="submit">Sign me up</Button>
              </div>
            </fieldset>
          </form>

          <div className="email-form-content">
            <h3 className="email--heading">Stay up to date.</h3>
            <p>
              Our weekly newsletter is a great way to stay informed on the latest products arriving at your favorite comic book store. Get notified about upcoming preorder discount items, and when our comic book subscription service is ready.
            </p>
          </div>
        </div>
      </section>

      <footer role="contentinfo">
        <div className="footer--container">
          <div className="footer--bucket">
            <h4>Our Comic Book Store location:</h4>
            <p>
              <strong>Zeus Comics and Collectibles</strong><br />
              1334 Inwood Rd<br />
              Dallas, TX 75247
            </p>

            <p>
              Phone: 214-219-8697 (TOYS) or<br />
            </p>

            <p className="map">
              <a href="http://maps.google.com/maps?q=1334+Inwood+Ave,+Dallas,+TX+75247,+USA&amp;ie=UTF8&amp;z=16&amp;iwloc=addr">Google Map</a>
            </p>
          </div>

          <div className="footer--bucket">
            <h5>Store Hours:</h5>
            <p>
              <span aria-label="Sunday">Sun</span>, 12pm-6pm<br />
              <span aria-label="Monday">Mon</span>, Closed<br />
              <span aria-label="Tuesday">Tue</span>, 12pm-6pm<br />
              <span aria-label="Wednesday">Wed</span>, 11am-7pm<br />
              <span aria-label="Thursday">Thu</span>, 11am-7pm<br />
              <span aria-label="Friday">Fri</span>, 11am-7pm<br />
              <span aria-label="Saturday">Sat</span>, 11am-7pm<br />
            </p>
          </div>

          <div className="footer--bucket">
            <nav>
              <ul className="ul-reset">
                <li><Link to="/about">About Zeus Comics</Link></li>
                <li><Link to="/checklist">Comic Book Pull List</Link></li>
                <li><Link to="/final-order-cutoff">Preorder discounts</Link></li>
                <li><Link to="/subscriptions">Subscription service</Link></li>
                <li><Link to="/about#returns">Refund &amp; Return policy</Link></li>
              </ul>
            </nav>
            <h5>Follow us!</h5>
            <div className="social">
              <div>
                <a href="https://www.facebook.com/zeuscomicsdallas" title="Facebook">
                  <FacebookIcon />
                </a>
              </div>
              <div>
                <a href="https://twitter.com/zeuscomics" title="Twitter">
                  <TwitterIcon />
                </a>
              </div>
              <div>
                <a href="https://www.instagram.com/zeuscomics/" title="Instagram">
                  <InstagramIcon />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
