import { FETCH_CART_EXPIRED } from '../actions/types';

const INITIAL_STATE = { all: {}, idempotency_key: `` };

const cartExpiredReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CART_EXPIRED:
      return { ...state, all: action.payload.data.cart, idempotency_key: `` };
    default:
      return state;
  }
}

export default cartExpiredReducer;
