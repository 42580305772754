import { FETCH_TAG, CLEAR_TAG } from '../actions/types';

const INITIAL_STATE = { tag: {} };

const tagReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_TAG:
      return { ...state, tag: action.payload };
    case CLEAR_TAG:
      return { ...state, tag: {} };
    default:
      return state;
  }
}

export default tagReducer;
