import { FETCH_CATEGORY } from '../actions/types';

const INITIAL_STATE = { docs: [], numFound: 0, title: '' };

const categoryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CATEGORY:
      // console.log(action.payload.data.response.numFound); // eslint-disable-line no-console
      return {
        ...state,
        docs: action.payload.data.response.docs,
        numFound: action.payload.data.response.numFound,
        title: action.payload.title,
      };
    default:
      return state;
  }
}

export default categoryReducer;
