import React from 'react';
import PropTypes from 'prop-types';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    if (window.Raven) {
      window.Raven.captureException(error);
      window.Raven.setUserContext({
        info
      });
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <div className="loading--content">
        <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
      </div>;
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.array,
}
export default ErrorBoundary;
