import { FETCH_FEATURE_SUCCESS } from '../actions/types';

const INITIAL_STATE = { all: [] };

const featureReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_FEATURE_SUCCESS:
      return { ...state, all: action.payload };
    default:
      return state;
  }
}

export default featureReducer;
