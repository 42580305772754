import {
  FETCH_CHECKLIST,
  VERIFY_CHECKLIST,
} from '../actions/types';

const INITIAL_STATE = { feed: [], idempotency_key: ``, valid: true };

const checklistReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CHECKLIST:
      return { ...state, feed: action.payload.message, idempotency_key: action.payload.idempotency_key };
    case VERIFY_CHECKLIST:
      return { ...state, valid: action.payload.valid };
    default:
      return state;
  }
}

export default checklistReducer;
