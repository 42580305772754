import { FETCH_CART, FETCH_CART_EMPTY } from '../actions/types';

const INITIAL_STATE = { all: {}, cartType: 'cart', idempotency_key: `` };

const cartReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CART:
      return { ...state, all: action.payload.data.cart, idempotency_key: action.payload.data.idempotency_key };
    case FETCH_CART_EMPTY:
      return { ...state, all: {}, idempotency_key: `` };
    default:
      return state;
  }
}

export default cartReducer;
