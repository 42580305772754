import { FETCH_SEARCH_RESULTS, UPDATE_SEARCH_TERM } from '../actions/types';

const INITIAL_STATE = { search_results: {}, term: '' };

const searchReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_SEARCH_RESULTS:
      return { ...state, search_results: action.payload };
    case UPDATE_SEARCH_TERM:
      return { ...state, term: action.payload };
    default:
      return state;
  }
}

export default searchReducer;
