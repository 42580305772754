import React, { useState } from 'react';
import { alpha } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';

import history from '../utils/history';

const useStyles = makeStyles()((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: `none`,
    [theme.breakpoints.up(`sm`)]: {
      display: `block`,
    },
  },
  search: {
    position: `relative`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: `100%`,
    [theme.breakpoints.up(`sm`)]: {
      marginLeft: theme.spacing(3),
      width: `auto`,
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: `100%`,
    position: `absolute`,
    pointerEvents: `none`,
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
  },
  inputRoot: {
    color: `inherit`,
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create(`width`),
    width: `100%`,
    [theme.breakpoints.up(`md`)]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: `none`,
    [theme.breakpoints.up(`md`)]: {
      display: `flex`,
    },
  },
  sectionMobile: {
    display: `flex`,
    [theme.breakpoints.up(`md`)]: {
      display: `none`,
    },
  },
}));


const SearchForm = () => {
  const [term, setTerm] = useState(``);
  const { classes } = useStyles();

  const changeTerm = (event) => {
    setTerm(event.target.value);
    // emitChange();
  }

  const handleKeyDown = (event) => {
    const keyEvents = [`Enter`];
    if (keyEvents.indexOf(event.key) > -1) {
      keyDownHandlers(event.key, event);
    }
  }

  const keyDownHandlers = (key, event) => {
    switch (key) {
      case `Enter`: {
        event.preventDefault();
        history.push(`/search?q=${term}`);
        // props.fetchSearchResults(term, 1);
        break;
      }
      default:
        break;
    }
  }

  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        placeholder="Search…"
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ 'aria-label': `search` }}
        onKeyDown={handleKeyDown}
        onChange={changeTerm}
        value={term}
      />
    </div>
  );
}

export default SearchForm;
