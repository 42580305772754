import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListSubheader from '@mui/material/ListSubheader';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

import SearchForm from './SearchForm';
import HeaderCart from './HeaderCart';

const useStyles = makeStyles()(theme => ({
  root: {
    flexGrow: 1,
    display: `flex`,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: `auto`,
  },
  icon: {
    minWidth: 38
  }
}));

const Header = () => {
  const {classes} = useStyles();

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (side, open) => event => {
    if (event && event.type === `keydown` && (event.key === `Tab` || event.key === `Shift`)) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const sideList = (side) => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <List>
        <ListSubheader component="div" id="nested-list-subheader">
          Shop
        </ListSubheader>
        <ListItem button component={Link} to="/new-releases">
          <ListItemIcon className={classes.icon}><NewReleasesIcon /></ListItemIcon>
          <ListItemText primary="New Products" />
        </ListItem>
        <ListItem button component={Link} to="/checklist">
          <ListItemText>Pull List</ListItemText>
        </ListItem>
        <ListItem button component={Link} to="/final-order-cutoff">
          <ListItemText>Weekly Preorders</ListItemText>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListSubheader component="div" id="nested-list-subheader">
          Browse
        </ListSubheader>
        <ListItem button component={Link} to="/category/comics">
          <ListItemText>
            Comic Books
          </ListItemText>
        </ListItem>
        <ListItem button component={Link} to="/category/graphic-novels">
          <ListItemText>
            Graphic Novels
          </ListItemText>
        </ListItem>
        <ListItem button component={Link} to="/category/toys">
          <ListItemText>
            Toys
          </ListItemText>
        </ListItem>
      </List>
    </div>
  );

  return (
    <>
    <SwipeableDrawer
      open={state.left}
      onClose={toggleDrawer(`left`, false)}
      onOpen={toggleDrawer(`left`, true)}
      >
      {sideList(`left`)}
    </SwipeableDrawer>

    <header className="header">
      <Toolbar className="header--container">
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          onClick={toggleDrawer(`left`, true)}
        >
          <MenuIcon />
        </IconButton>
        <div className={classes.root}>
          <div className="logo-block">
            <Link to="/" title="Home" className="logo" aria-label="back to home">
              <img src="https://s3-us-west-2.amazonaws.com/zeuscomicsassets/images/logo.svg" alt="Zeus Comics" />
            </Link>
          </div>
          <div className="navigation-block" aria-hidden="true">
            <nav>
              <span className="navigation--label">Shop by</span>
              <div className="navigation--links">
                <NavLink
                  activeClassName="active"
                  to="/new-releases"
                >
                  <div className="navigation-name">New Releases</div>
                </NavLink>
                <NavLink
                  activeClassName="active"
                  to="/checklist"
                >
                  <div className="navigation-name">Pull List</div>
                </NavLink>
                <NavLink
                  activeClassName="active"
                  to="/final-order-cutoff"
                >
                  <div className="navigation-name">Preorders</div>
                </NavLink>
              </div>
            </nav>
          </div>
        </div>
        <SearchForm />
        <HeaderCart />
      </Toolbar>
    </header>
    </>
  );
}

export default Header;
