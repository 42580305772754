export const FETCH_PICKS_REQUEST = 'FETCH_PICKS_REQUEST';
export const FETCH_PICKS_SUCCESS = 'FETCH_PICKS_SUCCESS';
export const FETCH_FEATURE_REQUEST = 'FETCH_FEATURE_REQUEST';
export const FETCH_FEATURE_SUCCESS = 'FETCH_FEATURE_SUCCESS'
export const FETCH_FEATURE_FOC_SUCCESS = 'FETCH_FEATURE_FOC_SUCCESS'
export const FETCH_PRODUCT = 'FETCH_PRODUCT';
export const CLEAR_PRODUCT = 'CLEAR_PRODUCT';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';

export const FETCH_CART = 'FETCH_CART';
export const FETCH_CART_EMPTY = 'FETCH_CART_EMPTY';
export const FETCH_CATEGORY = 'FETCH_CATEGORY';
export const FETCH_TAG = 'FETCH_TAG';
export const CLEAR_TAG = 'CLEAR_TAG';

export const AUTH_USER = 'AUTH_USER';
export const UNAUTH_USER = 'UNAUTH_USER';
export const FETCH_RELATED_PRODUCTS = 'FETCH_RELATED_PRODUCTS';
export const FETCH_CHECKLIST = 'FETCH_CHECKLIST';
export const ADD_CHECKLIST_ITEM = 'ADD_CHECKLIST_ITEM';
export const REMOVE_CHECKLIST_ITEM = 'REMOVE_CHECKLIST_ITEM';
export const UPDATE_CHECKLIST_ITEM = 'UPDATE_CHECKLIST_ITEM';
export const ADD_CHECKLIST = 'ADD_CHECKLIST';
export const FETCH_CHECKLIST_SHARE = 'FETCH_CHECKLIST_SHARE';
export const VERIFY_CHECKLIST = 'VERIFY_CHECKLIST';

export const FETCH_SEARCH_RESULTS = 'FETCH_SEARCH_RESULTS';
export const UPDATE_SEARCH_TERM = 'UPDATE_SEARCH_TERM';

export const FETCH_CART_EXPIRED = 'FETCH_CART_EXPIRED';

export const FETCH_NEWS_SUCCESS = 'FETCH_NEWS_SUCCESS';
export const FETCH_NEWS_ITEM_SUCCESS = 'FETCH_NEWS_ITEM_SUCCESS';
export const FETCH_NEWS_TAG_SUCCESS = 'FETCH_NEWS_TAG_SUCCESS';
export const CLEAR_NEWS = 'CLEAR_NEWS';

export const FETCH_FOC = 'FETCH_FOC';
export const ADD_FOC_ITEM = 'ADD_FOC_ITEM';
export const REMOVE_FOC_ITEM = 'REMOVE_FOC_ITEM';
export const UPDATE_FOC_ITEM = 'UPDATE_FOC_ITEM';
export const ADD_FOC = 'ADD_FOC';
export const ERROR_FOC = 'ERROR_FOC';

export const SQUARE_SUBMIT = 'SQUARE_SUBMIT';
export const SQUARE_ERROR = 'SQUARE_ERROR';
export const SQUARE_EMPTY = 'SQUARE_EMPTY';

export const ADD_FEED_ITEM = 'ADD_FEED_ITEM';
export const REMOVE_FEED_ITEM = 'REMOVE_FEED_ITEM';

export const PROCESSING = 'PROCESSING';
