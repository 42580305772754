import { reducer as FormReducer } from 'redux-form';
import { combineReducers } from 'redux';

import PicksReducer from './reducer-picks';
import FeatureReducer from './reducer-feature';
import FeatureFocReducer from './reducer-feature-foc';
import ProductReducer from './reducer-product';
import CartReducer from './reducer-cart';
import CartExpiredReducer from './reducer-cart-expired';
import CategoryReducer from './reducer-category';
import TagReducer from './reducer-tag';
import RelatedProducts from './reducer-related-products';
import ChecklistReducer from './reducer-checklist';
import ChecklistShareReducer from './reducer-checklist-share';
import SearchReducer from './reducer-search';
import NewsReducer from './reducer-news';
import FocReducer from './reducer-foc';
import SquareReducer from './reducer-square';
import ProcessingReducer from './reducer-processing';

const rootReducer = combineReducers({
  picks: PicksReducer,
  feature: FeatureReducer,
  featureFoc: FeatureFocReducer,
  product: ProductReducer,
  form: FormReducer,
  cart: CartReducer,
  cartExpired: CartExpiredReducer,
  category: CategoryReducer,
  tag: TagReducer,
  related_products: RelatedProducts,
  checklist: ChecklistReducer,
  checklist_share: ChecklistShareReducer,
  search: SearchReducer,
  news: NewsReducer,
  foc: FocReducer,
  square: SquareReducer,
  processing: ProcessingReducer
});

export default rootReducer;
