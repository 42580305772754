import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import ReactGA from 'react-ga';
import thunk from 'redux-thunk';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import * as Sentry from '@sentry/browser';

import reducers from './store/reducers';
import App from './components/App';
import * as keys from './utils/env';
import { FONT } from './utils/format';

import './index.scss';

const theme = createTheme({
  typography: {
    fontFamily: FONT.join(','),
  }
});

const createStoreWithMiddleware = applyMiddleware(thunk)(createStore);
const store = createStoreWithMiddleware(
  reducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);
export default store;

const environment = keys.ENV === 'production' ? keys.ENV : 'sandbox';

Sentry.init({ dsn: keys.SENTRY[environment] });

ReactGA.initialize(keys.GOOGLE[environment], {
  titleCase: false,
});
ReactGA.plugin.require('ecommerce');
ReactGA.pageview(window.location.pathname);

const container = document.getElementById('app');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </Provider>
);
