export const formatNumber = (n) => (parseFloat(Math.round(n * 100) / 100).toFixed(2));
export const formatInteger = (n) => Number(parseFloat(Math.round(n * 100) / 100).toFixed(0));

export const isNumber = (n) => !isNaN(parseFloat(n)) && isFinite(n);

export const itemPrice = (price, discount) => {
  if (isNumber(discount) && discount > 0) {
    return Number(price) * (1 - Number(discount));
  }

  return price;
}

export const TAX_RATE = 0.0825;
export const MYLAR = 0.30;
export const SHIPPING = 9;
export const DISCOUNT = 0.1;
export const SHIPPING_THRESHOLD = 100;

export const FONT = [
  "proxima-nova",
  "SF Pro Display",
  "San Francisco",
  "-apple-system",
  "Helvetica Neue",
  "Roboto",
  "Arial",
  "Helvetica",
  "sans-serif",
];

export const slugify = (text) => {
  if (!text) {
    return null;
  }

  return text.toString().toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
}

export const categoryInfo = (accountCode) => {
  const data = {};
  switch (accountCode) {
    case '1':
      data.urlTitle = 'comics'
      data.title = 'Comic Books'
      break;
    case '2':
    case '5':
    case '15':
      data.urlTitle = 'graphic-novels'
      data.title = 'Graphic Novels'
      break;
    case '3':
    case '6':
    case '8':
    case '9':
      data.urlTitle = 'toys'
      data.title = 'Toys'
      break;
    default:
      data.urlTitle = 'comics'
      data.title = 'Comic Books'
      break;
  }

  return data;
}

export const landingPages = [
  {
    keyword: `avengers`,
    small: `/images/loves/landing-avengers-large.jpg`,
    small2x: `/images/loves/landing-avengers-large.jpg`,
    large: `/images/loves/landing-avengers-large.jpg`,
    large2x: `/images/loves/landing-avengers-large-2x.jpg`,
  },
  {
    keyword: `batman`,
    small: `/images/loves/batman-small.jpg`,
    small2x: `/images/loves/batman-small-2x.jpg`,
    large: `/images/loves/batman-large.jpg`,
    large2x: `/images/loves/batman-large-2x.jpg`,
  },
  {
    keyword: `black-panther`,
    small: `/images/loves/landing-black-panther-large.jpg`,
    small2x: `/images/loves/landing-black-panther-large.jpg`,
    large: `/images/loves/landing-black-panther-large.jpg`,
    large2x: `/images/loves/landing-black-panther-large-2x.jpg`,
  },
  {
    keyword: `captain-marvel`,
    small: `/images/loves/landing-captain-marvel-large.jpg`,
    small2x: `/images/loves/landing-captain-marvel-large.jpg`,
    large: `/images/loves/landing-captain-marvel-large.jpg`,
    large2x: `/images/loves/landing-captain-marvel-large-2x.jpg`,
  },
  {
    keyword: `dc-comics`,
    small: `/images/loves/landing-dc-comics-small.jpg`,
    small2x: `/images/loves/landing-dc-comics-small-2x.jpg`,
    large: `/images/loves/landing-dc-comics-large.jpg`,
    large2x: `/images/loves/landing-dc-comics-large-2x.jpg`,
  },
  {
    keyword: `deadpool`,
    small: `/images/loves/landing-deadpool-large.jpg`,
    small2x: `/images/loves/landing-deadpool-large-2x.jpg`,
    large: `/images/loves/landing-deadpool-large.jpg`,
    large2x: `/images/loves/landing-deadpool-large-2x.jpg`,
  },
  {
    keyword: `fantastic-four`,
    small: `/images/loves/landing-f4-large.jpg`,
    small2x: `/images/loves/landing-f4-large.jpg`,
    large: `/images/loves/landing-f4-large.jpg`,
    large2x: `/images/loves/landing-f4-large-2x.jpg`,
  },
  {
    keyword: `funko`,
    small: `/images/loves/landing-funko-large.jpg`,
    small2x: `/images/loves/landing-funko-large.jpg`,
    large: `/images/loves/landing-funko-large.jpg`,
    large2x: `/images/loves/landing-funko-large-2x.jpg`,
  },
  {
    keyword: `justice-league`,
    small: `/images/loves/justice-league-small.jpg`,
    small2x: `/images/loves/justice-league-small-2x.jpg`,
    large: `/images/loves/justice-league-large.jpg`,
    large2x: `/images/loves/justice-league-large-2x.jpg`,
  },
  {
    keyword: `marvel-comics`,
    small: `/images/loves/landing-marvelcomics-small.jpg`,
    small2x: `/images/loves/landing-marvelcomics-small-2x.jpg`,
    large: `/images/loves/landing-marvelcomics-large.jpg`,
    large2x: `/images/loves/landing-marvelcomics-large-2x.jpg`,
  },
  {
    keyword: `spider-man`,
    small: `/images/loves/spider-man-small.jpg`,
    small2x: `/images/loves/spider-man-small-2x.jpg`,
    large: `/images/loves/spider-man-large.jpg`,
    large2x: `/images/loves/spider-man-large-2x.jpg`,
  },
  {
    keyword: `star-wars`,
    small: `/images/loves/landing-star-wars-large.jpg`,
    small2x: `/images/loves/landing-star-wars-large.jpg`,
    large: `/images/loves/landing-star-wars-large.jpg`,
    large2x: `/images/loves/landing-star-wars-large-2x.jpg`,
  },
  {
    keyword: `the-walking-dead`,
    small: `/images/loves/the-walking-dead-small.jpg`,
    small2x: `/images/loves/the-walking-dead-small-2x.jpg`,
    large: `/images/loves/the-walking-dead-large.jpg`,
    large2x: `/images/loves/the-walking-dead-large-2x.jpg`,
  },
  {
    keyword: `wonder-woman`,
    small: `/images/loves/wonder-woman-small.jpg`,
    small2x: `/images/loves/wonder-woman-small-2x.jpg`,
    large: `/images/loves/wonder-woman-large.jpg`,
    large2x: `/images/loves/wonder-woman-large-2x.jpg`,
  },
  {
    keyword: `x-men`,
    small: `/images/loves/landing-x-men-large.jpg`,
    small2x: `/images/loves/landing-x-men-large.jpg`,
    large: `/images/loves/landing-x-men-large.jpg`,
    large2x: `/images/loves/landing-x-men-large-2x.jpg`,
  },
];
