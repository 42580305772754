export const PAYPAL = {
  sandbox: 'AYMJbvbScPYNNL2xgF8n-EzoN-P_LmIFGtbSmHF1s-weTy-s3_tkqAmr40iV73pHgjCkuDiarY8jP87R',
  production: 'Aa5ARa_hFPCxjPD7IaElYQnRDcXCoWipxBVonc1EmGBCRqP0eJ9L_ZkStOrhQ71Z-td2SrxZ4GdRJgGi',
};

export const GOOGLE = {
  sandbox: 'UA-XXXXXXX-1',
  production: 'UA-1556374-1',
}

export const SQUARE = {
  sandbox: 'sandbox-sq0idb-jkRKsT-vI_lE1LYjWdZG6A',
  production: 'sq0idp-wC8lNF7IZBadPhOB3QrKWA',
}

export const SQUARE_URL = {
  sandbox: `https://sandbox.web.squarecdn.com/v1/square.js`,
  production: `https://js.squareup.com/v2/paymentform`,
}

export const SQUARE_LOCATION = {
  sandbox: "A7X4CWAQ44N2R",
  production: "8FN8EVAY8P1XP",
};

export const SENTRY = {
  sandbox: '',
  production: 'https://4206deb340f849238586c22286805f6a@sentry.io/1259014'
};

export const CHECKLIST_HOLD_AVAILABLE = true;

// development
// export const ENV = 'development';
// export const ROOT_URL = 'http://zeus.site/api';
// export const LOCATION_URL = 'http://localhost:3000';

// production
export const ENV = 'production';
export const ROOT_URL = 'https://www.zeuscomics.com/api';
export const LOCATION_URL = 'https://www.zeuscomics.com';
