import { FETCH_PICKS_SUCCESS } from '../actions/types';

const INITIAL_STATE = { all: [] };

const picksReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_PICKS_SUCCESS:
      return { ...state, all: action.payload };
    default:
      return state;
  }
}

export default picksReducer;
