import { createBrowserHistory } from 'history'
import ReactGA from 'react-ga';

const browserHistory = createBrowserHistory();

// const scrollHere = () => {
//   const elmnt = document.getElementById("content");
//   const returns = document.getElementById("returns");
//   if (returns) {
//     returns.scrollIntoView();
//   } else if(elmnt) {
//     elmnt.scrollIntoView();
//   } else {
//     window.scrollTo(0, 0);
//   }
// }

browserHistory.listen(() => {
  // scrollHere();
  window.scrollTo(0, 0);
  ReactGA.pageview(window.location.pathname + window.location.search);
});

export default browserHistory;
