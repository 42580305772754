import {
  FETCH_FOC,
  ERROR_FOC,
} from '../actions/types';

const INITIAL_STATE = { feed: [], idempotency_key: ``, isAvailable: true };

const FOCReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_FOC:
      return { ...state, feed: action.payload.message, idempotency_key: action.payload.idempotency_key };
    case ERROR_FOC: {
      return {...state, feed: [], isAvailable: false }
    }
    default:
      return state;
  }
}

export default FOCReducer;
