import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import {
  fetchCart,
} from '../store/actions/index';

const HeaderCart = () => {
  const cart = useSelector((state) => state.cart.all);
  const dispatch = useDispatch();

  const [invisible, setInvisible] = useState(true);
  const [content, setContent] = useState(0);

  // tag used for assigning affiliates
  useEffect(() => {
    const tag = getQueryVariable(`tag`);

    dispatch(fetchCart(tag));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const length = cart.product_row ? cart.product_row.length : 0;
    setContent(length);
    if (length > 0) {
      setInvisible(false);
    }
  }, [cart])

  const getQueryVariable = (variable) => {
    const query = window.location.search.substring(1);
    const vars = query.split(`&`);
    for (let i = 0; i < vars.length; i++) {
      var pair = vars[i].split(`=`);
      if (pair[0] === variable) {
        return pair[1];
      }
    }
    return(false);
  }

  return (
    <IconButton
      color="inherit"
      component={Link}
      to="/cart"
    >
      <Badge badgeContent={content} color="secondary" invisible={invisible}>
        <ShoppingCartIcon />
      </Badge>
    </IconButton>
  )
}

HeaderCart.propTypes = {
  fetchCart: PropTypes.func,
  cart: PropTypes.object,
};

export default HeaderCart;
