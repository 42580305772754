import { FETCH_NEWS_SUCCESS, FETCH_NEWS_ITEM_SUCCESS, FETCH_NEWS_TAG_SUCCESS, CLEAR_NEWS } from '../actions/types';

const INITIAL_STATE = { all: [], item: {} };

const newsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_NEWS_SUCCESS:
      return { ...state, all: action.payload.data.data };
    case FETCH_NEWS_ITEM_SUCCESS:
      return { ...state, item: action.payload.data.data };
    case FETCH_NEWS_TAG_SUCCESS:
      return { ...state, all: action.payload.data.data };
    case CLEAR_NEWS:
      return { ...state, item: {} }
    default:
      return state;
  }
}

export default newsReducer;
