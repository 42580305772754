import { FETCH_PRODUCT, CLEAR_PRODUCT } from '../actions/types';

const INITIAL_STATE = { product: {} };

const productsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_PRODUCT:
      return { ...state, product: action.payload };
    case CLEAR_PRODUCT:
      return { ...state, product: {} };
    default:
      return state;
  }
}

export default productsReducer;
