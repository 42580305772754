import {
  SQUARE_SUBMIT,
  SQUARE_ERROR,
  SQUARE_EMPTY,
} from '../actions/types';

const INITIAL_STATE = {
  square: {
    squar: {},
  },
  squareError: {},
};

const squareReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SQUARE_SUBMIT:
      return { ...state, square: action.payload };
    case SQUARE_ERROR:
      return { ...state, squareError: action.payload };
    case SQUARE_EMPTY: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
}

export default squareReducer;
