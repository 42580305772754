import { PROCESSING } from '../actions/types';

const INITIAL_STATE = { processing: false };

const processingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PROCESSING:
      return { ...state, processing: action.payload };
    default:
      return state;
  }
}

export default processingReducer;
