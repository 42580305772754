import { FETCH_RELATED_PRODUCTS, CLEAR_PRODUCT } from '../actions/types';

const INITIAL_STATE = { products: [] };

const relatedProductsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_RELATED_PRODUCTS:
      return { ...state, products: action.payload.data.data.response.docs };
    case CLEAR_PRODUCT:
      return { ...state, products: {} };
    default:
      return state;
  }
}

export default relatedProductsReducer;
