import { FETCH_CHECKLIST_SHARE } from '../actions/types';

const INITIAL_STATE = { checklist: [] };

const checklistShareReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CHECKLIST_SHARE:
      return { ...state, checklist: action.payload };
    default:
      return state;
  }
}

export default checklistShareReducer;
